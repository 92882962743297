// brand colors:
$golden: #e9b468;
$golden-heavy: #ea9a28;
$golden-light: #fcf4e9;
$golden-light-2: #ffecc7;

$gray-0: #808080;
$gray-1: #7a7e80;
$gray-2: #83807b;
$gray-3: #9b9b9c;
$gray-4: #96999b;
$gray-5: #e7e8ea;
$gray-6: #f9f9f9;
$gray-7: #f5f5f5;
$gray-8: #E8E8E8;
$gray-9: #c9c9c9;
$lightGrayButton: #F2F4F5;
$lightGrayButtonHover: #E4E4E4;
$black: #414244;
$white: #fff;

$green: #30bd4999; //from design: #0F9E33;
$dark-green: #0e9f32;
$medium-green: #28A745;
$medium-green-op-10: rgba($medium-green, .1);
$alert-red: #DE0000;
$alert-red-op-10: rgba($alert-red, .08);
$light-green: #c0e6c9;
$lighter-green: #ebf7ee;
$blue: #007bff;
$light-blue: #e5f1ff;
$red: #d31605;
$light-red: #f2b8b3;
$lighter-red: #eedada;
$dark-yellow: #e4a347;

$chart-active-color: rgb(81, 220, 130);
$chart-inactive-color: rgba(81, 220, 130, 0.22);
$chart-outer-color: rgba(81, 220, 130, 0.1);

$table-title: #E4E4E4;

// additional colors, out of design:

$dark: rgb(33, 35, 37);
$darker: #202020;
$mildly-dark: #303030;
$not-so-dark: #404040;
$brand-color-1: $golden-heavy;
$gray-light: #dee2e6;
$gray-not-so-light: #e4e4e4;
$gray-medium: #74767a;
$gray-dark: #414244;
$gray-darker: #28292b;

$brand-color-1-op8: scale-color($golden-heavy, $lightness: 20%);
$brand-color-1-op1: scale-color($golden-heavy, $lightness: 90%);

// other custom variables:
$animation-duration: 1s;
$sm-dot-size: 2px;
$circle-border-radius: 100%;

// screen widths:
$sm-device-width: 768px;
$md-device-width: 1024px;

// bootstrap override:
$body-color: $black;
$primary: $golden;
$success: #4BB543;
$secondary: $gray-5;
$info: $blue;
$body-bg: $gray-6;
$table-hover-bg: rgba($gray-5, 0.35);
$text-muted: #96999b;

$border-radius: 0.4rem;
$border-radius-2: 0.2rem;
$border-radius-3: 0.25rem;
$btn-border-radius: 1.5rem;
$border-radius-sm: 1.5rem;
$input-btn-padding-x: 1.4rem;
$input-btn-padding-x-sm: 1rem;

$font-size-xsm: 0.7rem;
$font-size-regular: 0.9rem;
$font-size-regular-plus: 1rem;
$font-size-md: 1.2rem;
$font-size-lg: 1.5rem;
$font-size-base: 0.9rem;

$font-family-base: 'Roboto';

$fontWeightRegular: 400;
$fontWeightSemiBold: 700;
$fontWeightBold: 900;

$box-shadow-main: 0.2rem 0.7rem 0.5rem 0 rgba($black, 0.03);
$input-btn-focus-width: 0;
$input-border-color: #ced4da;
$input-focus-border-color: $text-muted;

// paddings & margins, pm-3 is the same value like in bootstrap .p-3 or .m-3:
$pm-3: 1rem;
$pm-4: 1.5rem;
$pm-5: 3rem;

//Variables for elements

$navbar-height: 72px;
$nav-tab-button-active-height: 48px;
$nav-tab-button-active-bg: $black;
$nav-tab-button-active-text-color: $white;
$nav-tab-button-height: 40px;
$nav-tab-button-bg: $gray-darker;
$nav-tab-button-text-color: $gray-2;

// TODO: clean when old product selector will be removed
$product-select-button-active-height: 40px;
$product-select-button-active-bg: $golden;
$product-select-button-active-text-color: $darker;
$product-select-button-height: 32px;
$product-select-button-bg: $gray-darker;
$product-select-button-text-color: $gray-2;

$squareButtonSize: 24px;
$squareButtonSizeSmall: 18px;
$squareButtonSizeLarge: 34px;
$maxSquareButtonIconSize: 16px;

$activeButtonBorderBlue: #C9D5FF;
