.btn {
  border-radius: 0.2rem !important;
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-primary,
.btn-success {
  &:disabled {
    color: $white;
    background-color: $gray-5;
    opacity: 1;
    border-color: $gray-5;
  }
}
.card {
  border: none;
  box-shadow: $box-shadow-main;
}
.form-control[readonly] {
  border-color: $input-border-color;
  cursor: auto;
}
thead {
  border-bottom: 1px solid $gray-light;
}

th {
  border: none !important;
  text-transform: uppercase;
  font-size: $font-size-xsm;
  color: $gray-3;
  word-spacing: 0.2rem;
  text-align: center;
}

td {
  border-color: lighten($gray-light, 7%) !important;
  vertical-align: middle !important;
  text-align: center;
}

.text-success {
  color: #28A745 !important;
}

// override styles for Tabs:
.narrow-tabs {
  .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.nav.nav-pills {
  align-items: center;
  border-bottom: none;
  justify-content: center;
  padding-top: 0.5rem !important;
  position: relative;

  .nav-link {
    background-color: $nav-tab-button-bg;
    border-radius: 0;
    border: none;
    color: $nav-tab-button-text-color;
    height: $nav-tab-button-height;
    font-size: 14px;
    margin-top: 6px;
    margin-left: 1px;
    padding: 10px 32px;
    text-align: center;
    transition: background-color $animation-duration;
    z-index: 2;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active {
      align-items: center;
      background-color: $nav-tab-button-active-bg;
      border-radius: $border-radius;
      color: $nav-tab-button-active-text-color;
      display: inline-flex;
      height: $nav-tab-button-active-height;
      text-align: center;
      z-index: 3;
    }
  }
}

section {
  & > .tab-content {
    margin-top: 4rem;
  }
}
// end of override styles for Tabs

// EXCEPTIONS:

//styles for PCF form - couldn't work from styled components:
.auto-sum-input {
  background-color: lighten($light-green, 10%) !important;
  border: none;
  color: $dark-green;
  &.is-invalid {
    color: $gray-dark;
    background-color: $gray-light !important;
  }
}

.badge {
  border-radius: $btn-border-radius;
  padding: 0.6em;
}
