@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 3s infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
